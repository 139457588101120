import { React } from "react";
import CountdownTimer from "../components/CountDownTimer";

function Home() {
  return (
    <div id="home">
      <CountdownTimer />
    </div>
  );
}

export default Home;
